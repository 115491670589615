<template>
	<main class="single">
		<div
			v-if="component"
			class="background"
		>
			<component
				:is="component"
				@step="changeStep"
			/>
		</div>
	</main>
</template>

<script>
/**
 * @class Forgot
 *
 * Forgot password component with two steps
 * 1. Type in an email
 * 2. Show email successfully sent
 */
import ForgotEmailSent from './ForgotEmailSent.vue';
import ForgotForm from './ForgotForm.vue';
import _get from 'lodash/get';
import _keys from 'lodash/keys';

export default {
	name: 'Forgot',
	components: {
		ForgotEmailSent,
		ForgotForm
	},
	/**
	 * @ignore
	 */
	data() {
		return {
			step: 'ForgotForm',
			components: {
				ForgotForm: 'ForgotForm',
				ForgotEmailSent: 'ForgotEmailSent'
			}
		};
	},

	computed: {
		/**
		 * Return compoonent
		 * @return {object}  vue component
		 */
		component() {
			return _get(this.components, this.step, '');
		}
	},

	methods: {
		/**
		 * Go to next step when step supplied is valid
		 * @param {string} step	component step
		 */
		changeStep({step}) {
			const validSteps = _keys(this.components);
			const isValid = validSteps.indexOf(step) > -1;
			if (isValid) {
				this.step = step;
			}
		}
	}
};
</script>
