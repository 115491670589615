<template>
	<section class="form">
		<h2>{{ i18n.t('Forgot Your Password?') }}</h2>
		<form @submit="handleSubmit">
			<RegexInput
				ref="username"
				v-model="username"
				:label="i18n.t('Username')"
				:required="true"
			/>
			<FooterField
				:submit="{label: i18n.t('Submit')}"
				:cancel="false"
				@submit="handleSubmit"
			/>
		</form>
	</section>
</template>

<script>
/**
 * @class ForgotForm
 *
 * A form for requesting a password reset
 */
import FooterField from 'tucows-ui-components/src/FooterField.vue';
import RegexInput from 'tucows-ui-components/src/Input/RegexInput.vue';
import {mapActions, mapState} from 'vuex';
import _get from 'lodash/get';

export default {
	name: 'ForgotForm',
	components: {
		FooterField,
		RegexInput
	},
	/**
	 * @ignore
	 */
	data() {
		return {username: ''};
	},
	computed: {...mapState({errors: (state) => _get(state, 'forgot.errors')})},
	methods: {
		...mapActions({sendEmail: 'forgot/send'}),
		/**
		 * Request password reset with username
		 */
		handleSubmit() {
			if (this.$refs.username.isValid()) {
				this.sendEmail({username: this.username}).then(() => {
					// If no errors then go to next step
					if (!this.errors) {
						this.$emit('step', {step: 'ForgotEmailSent'});
					}
				});
			}
		}
	}
};
</script>
