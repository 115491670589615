var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "single" }, [
    _vm.component
      ? _c(
          "div",
          { staticClass: "background" },
          [
            _c(_vm.component, {
              tag: "component",
              on: { step: _vm.changeStep },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }