<template>
	<section class="form">
		<h2>{{ i18n.t('Password Reset Email Sent') }}</h2>
		<p>{{ i18n.t('A message has been sent to you by email with instructions on how to reset your password.') }}</p>
	</section>
</template>

<script>
export default {name: 'ForgotEmailSent'};
</script>
