var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "form" }, [
    _c("h2", [_vm._v(_vm._s(_vm.i18n.t("Forgot Your Password?")))]),
    _vm._v(" "),
    _c(
      "form",
      { on: { submit: _vm.handleSubmit } },
      [
        _c("RegexInput", {
          ref: "username",
          attrs: { label: _vm.i18n.t("Username"), required: true },
          model: {
            value: _vm.username,
            callback: function ($$v) {
              _vm.username = $$v
            },
            expression: "username",
          },
        }),
        _vm._v(" "),
        _c("FooterField", {
          attrs: { submit: { label: _vm.i18n.t("Submit") }, cancel: false },
          on: { submit: _vm.handleSubmit },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }