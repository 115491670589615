var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "form" }, [
    _c("h2", [_vm._v(_vm._s(_vm.i18n.t("Password Reset Email Sent")))]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        _vm._s(
          _vm.i18n.t(
            "A message has been sent to you by email with instructions on how to reset your password."
          )
        )
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }